import gql from 'graphql-tag';

import { ACTION_PROPERTY_DESCRIPTION_FRAGMENT } from './Property';

export const INTEGRATION_DETAILS_FRAGMENT = gql`
  fragment IntegrationDetails on IntegrationDetails {
    Webhook {
      url
      format
    }
    SSO {
      domain
      clientId
      clientSecret
      openIdIssuerUrl
    }
    Thinkific {
      subdomain
    }
    EmailTransport {
      testEmail
      sendHeader
      sendFooter
    }
    SendGrid {
      group
    }
    SMTP {
      host
      port
      username
      password
    }
    Slack {
      channel {
        id
        name
      }
      oneChannelPerWebinar
      channelPrefix
      onlyShowAttendeesWhoChat
      mentionUsers
    }
    Twilio {
      accountSID
      authToken
      phoneNumber
      responseMessage
      testNumber
      channel
    }
    Salesforce {
      type
      createActivities
      fieldsToExclude
      campaign
      subject
    }
    Marketo {
      identityUrl
      clientId
      clientSecret
    }
    ConvertKit {
      formId
    }
    Vimeo {
      accessToken
    }
    Chatbot {
      embedCodes
    }
    Zendesk {
      snippetKey
    }
    Drift {
      appId
    }
    Intercom {
      region
      appId
    }
    Hubspot {
      enableChat
    }
  }
`;

export const THIRD_PARTY_INTEGRATION_FRAGMENT = gql`
  fragment ThirdPartyIntegration on ThirdPartyIntegration {
    id
    type
    details {
      Zendesk {
        snippetKey
      }
      Drift {
        appId
      }
      Intercom {
        region
        appId
      }
      Chatbot {
        embedCodes
      }
    }
  }
`;

export const INTEGRATION_HOOK_DETAILS_FRAGMENT = gql`
  fragment IntegrationHookDetails on IntegrationHookDetails {
    Mailchimp {
      list {
        id
        name
      }
    }
    ActiveCampaign {
      list {
        id
        name
      }
    }
    ConvertKit {
      tag {
        id
        name
      }
    }
    ConstantContact {
      list {
        list_id
        name
      }
    }
    MailerLite {
      group {
        id
        name
      }
    }
  }
`;

export const INTEGRATION_FILTERS_FRAGMENT = gql`
  fragment IntegrationFilters on IntegrationFilters {
    minWatchedPercent
    maxWatchedPercent
  }
`;

export const INTEGRATION_TAGS_FRAGMENT = gql`
  fragment IntegrationTags on IntegrationTags {
    applyWebinarTags
    tags
    applyMissedWatchedTags
    tagsIfWatched
    tagsIfMissed
  }
`;

export const INTEGRATION_HOOK_FRAGMENT = gql`
  fragment IntegrationHook on IntegrationHook {
    id
    type
    tag
    action
    connected
    totalErrors
    errorResetAt
    mappings {
      name
      format
      customField {
        id
        name
        label
        type
      }
    }
    filters {
      ...IntegrationFilters
    }
    details {
      ...IntegrationHookDetails
    }
    tagValues {
      ...IntegrationTags
    }
    connected
  }
  ${INTEGRATION_HOOK_DETAILS_FRAGMENT}
  ${INTEGRATION_FILTERS_FRAGMENT}
  ${INTEGRATION_TAGS_FRAGMENT}
`;

export const INTEGRATION_FRAGMENT = gql`
  fragment Integration on Integration {
    id
    name
    type
    desc {
      inBeta
    }
    transport
    apiUrl
    apiKey
    apiSecret
    connected
    forUser
    forUserEmail
    connectedMessage
    performRedirect
    tagValues {
      ...IntegrationTags
    }
    details {
      ...IntegrationDetails
    }
    totalErrors
    errorResetAt
    integrationHooks {
      ...IntegrationHook
    }
  }
  ${INTEGRATION_TAGS_FRAGMENT}
  ${INTEGRATION_HOOK_FRAGMENT}
  ${INTEGRATION_DETAILS_FRAGMENT}
  ${INTEGRATION_FILTERS_FRAGMENT}
`;

export const GET_INTEGRATIONS = gql`
  query teamIntegrations {
    teamIntegrations {
      ...Integration
    }
  }
  ${INTEGRATION_FRAGMENT}
`;

export const GET_INTEGRATION_TYPES = gql`
  query integrationTypes {
    integrationTypes {
      type
      inBeta
    }
  }
`;

export const INTEGRATION_QUERIES = gql`
  mutation addIntegration($data: IntegrationUpdateInput!) {
    addIntegration(data: $data) {
      ...Integration
    }
  }

  mutation updateIntegration($data: IntegrationUpdateInput!) {
    updateIntegration(data: $data) {
      ...Integration
    }
  }

  mutation removeIntegration($id: String!, $integrationHookId: String) {
    removeIntegration(id: $id, integrationHookId: $integrationHookId)
  }

  mutation regenerateIntegrationKey($id: String!) {
    regenerateIntegrationKey(id: $id) {
      ...Integration
    }
  }

  mutation toggleIntegrationHook($id: String!, $on: Boolean!) {
    toggleIntegrationHook(id: $id, on: $on) {
      ...IntegrationHook
    }
  }

  ${INTEGRATION_HOOK_FRAGMENT}
`;

export const GET_SSO_USERINFO = gql`
  query ssoUserInfo($setId: String!, $attendeeId: String) {
    ssoUserInfo(setId: $setId, attendeeId: $attendeeId) {
      sub
      email
      name
      given_name
      family_name
      middle_name
      phone_number
      address
      locale
    }
  }
`;

export const INTEGRATION_ACTION_FRAGMENT = gql`
  fragment IntegrationAction on IntegrationAction {
    id
    correlationId
    type
    action
    method
    url
    payload
    status
    statusText
    error
    response
    createdAt
    retriable
  }
`;

export const GET_INTEGRATION_ACTIONS = gql`
  query integrationActionsFiltered($filters: IntegrationActionsFilter!) {
    integrationActionsFiltered(filters: $filters) {
      total
      nextCursor
      integrationActions {
        ...IntegrationAction
      }
    }
  }
  ${INTEGRATION_ACTION_FRAGMENT}
`;

export const RETRY_INTEGRATION_HOOK = gql`
  mutation retryIntegrationHook($id: String!) {
    retryIntegrationHook(id: $id) {
      ...IntegrationAction
    }
  }
  ${INTEGRATION_ACTION_FRAGMENT}
`;

export const GET_BUILTIN_PROPERTY_DESCRIPTIONS = gql`
  query builtInPropertyDescriptions($consumer: String) {
    builtInPropertyDescriptions(consumer: $consumer) {
      ...ActionPropertyDescription
    }
  }
  ${ACTION_PROPERTY_DESCRIPTION_FRAGMENT}
`;

export const GET_PROPERTY_DESCRIPTIONS = gql`
  query propertyDescriptions($filter: PropertyFilterInput!) {
    propertyDescriptions(filter: $filter) {
      ...ActionPropertyDescription
    }
  }
  ${ACTION_PROPERTY_DESCRIPTION_FRAGMENT}
`;

export const VIEW_INTEGRATIONS = gql`
  mutation viewIntegrations($integrationId: String, $integrationHookId: String) {
    viewIntegrations(integrationId: $integrationId, integrationHookId: $integrationHookId) {
      integration {
        id
        totalErrors
        errorResetAt
      }
      integrationHook {
        id
        totalErrors
        errorResetAt
      }
    }
  }
`;
